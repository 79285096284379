/* CandidateProfiles.css - Updated to match Figma mockup */

/* Main container styling */
.election-details-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  /* Main content area */
  .profiles-main-content {
    margin: 100px auto 100px;
    max-width: 1200px;
    width: 90%;
    padding: 0 20px;
  }
  
  .profiles-heading {
    font-size: 32px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 15px;
    text-align: center;
  }
  
  .profiles-heading::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 100px;
    height: 2px;
    background-color: #FFFFFF;
  }
  
  /* Profiles container with sidebar and details panel */
  .profiles-container {
    display: flex;
    background-color: transparent;
    gap: 20px;
    min-height: 600px;
  }
  
  /* Sidebar with candidates list */
  .candidates-sidebar {
    width: 335px;
    flex-shrink: 0;
    background-color: transparent;
  }
  
  .candidates-list {
    background-color: #E9E9E9;
    border-radius: 15px;
    overflow: hidden;
  }
  
  /* Candidate item in sidebar */
  .candidate-item {
    display: flex;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.2s;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .candidate-item:hover {
    background-color: #D7CDC1;
  }
  
  .candidate-item.active {
    background-color: #E9E9E9;
  }
  
  .candidate-item-photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #01395E;
  }
  
  .candidate-item-placeholder {
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
  }
  
  .candidate-item-info {
    flex: 1;
  }
  
  .candidate-item-name {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin: 0 0 5px 0;
  }
  
  .candidate-item-position {
    font-size: 14px;
    color: #555;
    margin: 0 0 5px 0;
  }
  
  .candidate-item-party {
    display: inline-block;
    font-size: 14px;
    color: #666;
  }
  
  /* Main candidate details panel */
  .candidate-details-panel {
    flex: 1;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  
  .candidate-profile-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  /* Candidate header section */
  .candidate-header {
    display: flex;
    align-items: center;
    padding: 30px;
    background-color: #01395E;
    color: white;
  }
  
  .candidate-photo {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .candidate-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .placeholder-photo {
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    font-weight: bold;
  }
  
  .candidate-details {
    flex: 1;
  }
  
  .candidate-name {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
  }
  
  .candidate-position {
    font-size: 18px;
    margin-bottom: 10px;
    color: white;
  }
  
  /* Party tags */
  .party-tag {
    display: inline-block;
    padding: 6px 14px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    background-color: white;
    color: #01395E;
  }
  
  /* Tabs */
  .candidate-tabs {
    display: flex;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .tab-button {
    padding: 10px 20px;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-bottom: none;
    cursor: pointer;
    font-weight: 500;
  }
  
  .tab-button.active {
    background-color: white;
    border-bottom: 2px solid #01395E;
  }
  
  /* Tab content */
  .tab-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  /* Social tab specific styling */
  .social-tab {
    padding: 10px;
  }
  
  .social-content .section-title {
    font-size: 20px;
    font-weight: 600;
    color: #01395E;
    margin-bottom: 15px;
  }
  
  .social-links-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .social-link {
    color: blue;
    text-decoration: underline;
    margin-right: 15px;
    font-size: 16px;
  }
  
  /* Recent posts styling */
  .recent-posts .section-title {
    font-size: 20px;
    font-weight: 600;
    color: #01395E;
    margin-bottom: 15px;
  }
  
  .post-card {
    margin-bottom: 20px;
  }
  
  .post-header {
    margin-bottom: 10px;
  }
  
  .post-date, .post-platform {
    display: inline-block;
    margin-right: 10px;
    font-weight: 500;
  }
  
  .post-content {
    margin-bottom: 10px;
    line-height: 1.5;
  }
  
  .post-link {
    color: blue;
    text-decoration: underline;
  }
  
  /* Category titles styling (President, Senator) for the second mockup */
  .category-title {
    font-size: 24px;
    font-weight: 600;
    color: white;
    margin: 30px 0 20px 10px;
  }
  
  /* Candidate grid for the second mockup */
  .category-candidates {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .grid-candidate-item {
    background-color: #01395E;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .grid-candidate-photo {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 15px;
  }
  
  .grid-candidate-name {
    color: white;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
  
  /* Candidate detail cards for the second mockup */
  .candidate-details-right {
    background-color: white;
    border-radius: 15px;
    padding: 20px;
  }
  
  .candidate-details-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .candidate-details-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  
  .candidate-details-info {
    flex: 1;
  }
  
  .candidate-details-name {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #01395E;
  }
  
  .candidate-details-party {
    font-size: 16px;
    color: #666;
  }
  
  .candidate-stats {
    display: flex;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .stat-item {
    flex: 1;
    padding: 15px;
    text-align: center;
    border-right: 1px solid #e0e0e0;
  }
  
  .stat-label {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
  }
  
  .stat-value {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  
  .detail-tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .detail-tab {
    padding: 10px 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 500;
    color: #01395E;
  }
  
  .detail-tab.active {
    font-weight: 600;
    border-bottom: 2px solid #01395E;
  }
  
  .detail-content {
    margin-bottom: 30px;
  }
  
  .endorsement-item, .policy-item {
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 10px 15px;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
  }
  
  /* Message for no candidate selected */
  .no-candidate-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    text-align: center;
    color: #666;
    font-style: italic;
    padding: 20px;
  }
  
  /* Responsive styles */
  @media (max-width: 992px) {
    .profiles-container {
      flex-direction: column;
    }
    
    .candidates-sidebar {
      width: 100%;
      margin-bottom: 20px;
    }
    
    .candidate-header {
      flex-direction: column;
      text-align: center;
    }
    
    .candidate-photo {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }